'use client'
import Image from 'next/image'
import { useInView } from 'react-intersection-observer'
import { useIsMobile } from '@hooks/usIsMobile'
import LanguageSwitch from '@components/atoms/LanguageSwitch'
import OutlineButton from '@components/atoms/OutlineButton'

export default function Header () {
  const { ref, inView } = useInView()

  const isMobile = useIsMobile()

  return (
    <section className='relative flex flex-col items-center'>
      <div className={`py-9 px-6 w-full flex justify-center ${inView ? 'relative' : 'fixed top-0 left-0 z-30 show-from-above'}`} style={{ background: 'linear-gradient(180deg, #0A1F28 50.33%, rgba(10, 31, 40, 0.93) 83.13%, rgba(10, 31, 40, 0) 95.65%)' }}>
        <div className='w-full flex items-center justify-between max-w-[1100px]'>
          <div className='relative w-fit h-[56px] items-center hidden md:flex'>
            {
              <Image priority src={'/img/happy_marmots_logo.webp'} width={224} height={56} quality={100} alt='Happy marmots' className='!max-w-[224px] !w-[224px] !h-[56px]' />
            }
          </div>
          <div className='flex items-center justify-between md:justify-end w-full gap-4'>
            <OutlineButton text={'Mint your NFT'} link='https://mint.happymarmots.io/' longerButton={false} className='xl:!text-[16px] !px-8 !py-4' />

            {
              isMobile ? <LanguageSwitch /> : <div className='flex items-center'><LanguageSwitch /></div>
            }
          </div>
        </div>
      </div>
      <div ref={ref} className='mt-24 mb-20 w-full max-w-[90%] md:max-w-[850px]'>
        <Image src='/img/header-illustration.webp' width={1000} height={600} className='!w-full !h-auto' alt='Happy marmots' />
      </div>
      <button>
        <Image src='/img/slide-down-button.svg' width={70} height={72} alt='Explore the rest of the page' className='floating-animation w-10 h-10 md:w-[70px] md:h-[70px]' />
      </button>
    </section>
  )
}
