import { useLocale, useTranslations } from 'next-intl'
import { useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'

export default function OafToken () {
  const t = useTranslations('token')
  const lang = useLocale()
  const [selectedSection, setSelectedSection] = useState(0)
  const [displayAnimation, setDisplayAnimation] = useState(false)

  const handleChange = (index: number) => {
    setSelectedSection(index)
    setDisplayAnimation(true)
    setTimeout(() => {
      setDisplayAnimation(false)
    }, 500)
  }

  const content = [
    {
      title: t('0.title'),
      description: t('0.description'),
      amount: t('0.amount'),
      percentage: t('0.percentage'),
      with: t('0.with')
    },
    {
      title: t('1.title'),
      items: [
        { title: t('1.1.title'), description: t('1.1.description') }, { title: t('1.2.title'), description: t('1.2.description') }, { title: t('1.3.title'), description: t('1.3.description') }, { title: t('1.4.title'), description: t('1.4.description') }, { title: t('1.5.title'), description: t('1.5.description') }
      ]
    }
  ]

  return (
    <section>
      <div className='mt-[300px] ml-12'>
        <div className='flex items-end w-fit relative'>
          <h1>$OAF</h1>
          <div
            className='absolute bottom-[40%]'
            style={{ left: 'calc(100% + 48px)' }}
          >
            <div className='relative w-fit'>
              <Image
                src='/img/team-title.webp'
                width={350}
                height={250}
                alt='Token'
                style={{ width: 'calc(100% + 60px)' }}
              />
              <p
                className='font-londrina text-secondary text-[80px] whitespace-nowrap pl-8 pr-32 -mt-[100px] uppercase'
                style={{ transform: 'rotate(-4.65deg)' }}
              >
                {t('token')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className='text-[24px] leading-[48px] mt-20'>{t('description')}</p>
      <div className={`grid gap-[70px] ${styles.columns} mt-20`}>
        <div>
          {
            content.map(({ title }, index) => {
              return (
                <button
                  key={title}
                  onClick={() => handleChange(index)}
                  className='flex items-center mb-12'
                >
                  <Image src={selectedSection === index ? '/img/mark.svg' : '/img/mark-empty.svg'} width={38} height={50} alt='' />
                  <p className={`flex items-center ml-6 ${selectedSection === index ? 'font-londrina text-orange' : 'font-londrina-outline text-orange'} text-[52px] xl:text-[60px] leading-[80px]`}>{t(`${index}.title`)}</p>
                </button>
              )
            })
          }
        </div>
        <div className='w-full'>
          <div className={`text-white font-medium text-[24px] leading-[48px] text-start w-full ${lang === 'en' ? 'h-[500px]' : 'h-[600px]'} lg:h-[450px] ${displayAnimation && styles.displayAnswer}`}>
            {
              selectedSection === 0
                ? <div><span className={styles.highlight}>{t(`${selectedSection}.amount`)}</span>{t(`${selectedSection}.with`)}<span className={styles.highlight}>{t(`${selectedSection}.percentage`)}</span>{t(`${selectedSection}.description`)}</div>
                : <ul style={{ listStyleImage: "url('/img/list-item.svg')" }} className='ml-2'>
                  {
                    content[selectedSection].items?.map(item => {
                      return (
                        <li key={item.title} className='text-[24px] leading-[48px] pl-1'>
                          <span className={styles.highlight}>{item.title}</span>{item.description}
                        </li>
                      )
                    })
                  }
                </ul>
            }
          </div>
        </div>
      </div>
    </section>
  )
}
