import OutlineButton from '@components/atoms/OutlineButton'
import { useIsTablet } from '@hooks/useIsTablet'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

export default function WhitepaperCard () {
  const t = useTranslations('whitepaper')
  const isTablet = useIsTablet()
  return (
    <div className='h-fit relative w-full max-w-[980px]'>
      <div className='pl-[70px] xl:px-[70px] gap-0 xl:gap-10 grid grid-cols-2 relative z-10 w-full'>
        <div className='pl-4 py-24 flex flex-col justify-center'>
          <p className='text-[24px] mt-4 leading-[48px] font-medium'>{t('dive')}</p>
          <div className='mt-[56px]'>
            <OutlineButton text={t('download')} link='/WHITEPAPER-OAF.pdf' className='uppercase' />
          </div>
        </div>
        <div className='relative xl:-translate-y-[12%] flex justify-center'>
          <Image src='/img/whitepaper-marmot.webp' width={390} height={503} alt='Download our whitepaper' className='md:w-[240px] md:h-[330px] xl:w-[370px] xl:h-[480px]' />
        </div>
      </div>
      <Image src={isTablet ? '/img/card-tablet.svg' : '/img/card-bg.svg'} fill alt='Whitepaper' />
    </div>
  )
}
